import { InputAdornment, LinearProgress } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { CustomTextField, FilledButton } from '../../Components/CustomMui/CustomMui'
import { BaseUrl, getSearchResults } from '../../Helpers/HelperFunctions'
import { IMember } from '../../Helpers/Interfaces'
import { Add, Search } from '@mui/icons-material';
import ViewEditModal from './ViewEditModal'
import axios from 'axios'
import { toast } from 'react-toastify'
import AddMemberModal from './AddMemberModal'
import { UserContextType } from '../../Helpers/Types'
import { UserContext } from '../../Store/Store'

function Members() {
  const { user } = useContext(UserContext) as UserContextType;
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [selectedMember, setSelectedMember] = useState(null as IMember | null);
  const [members, setMembers] = useState([] as IMember[])
  const [searchedMembers, setSearchedMembers] = useState([] as IMember[])
  const [showViewEditModal, setShowViewEditModal] = useState(false as boolean)
  const [showAddModal, setShowAddModal] = useState(false as boolean)

  useEffect(() => {
    getMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getMembers() {
    setIsLoading(true)
    await axios.get(BaseUrl + 'member').then((response) => {
      setMembers(response.data.result)
      setSearchedMembers(response.data.result)
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handleSearch = (searchKeyWord: string) => {
    const searchResults = getSearchResults(searchKeyWord, members)
    setSearchedMembers(searchResults)
  }

  function closeViewEditModal() {
    setShowViewEditModal(false)
    setSelectedMember(null)
  }

  return (
    <>
      <div className='page-wrapper'>
        <div className='justify-between flex-wrap mb-3'>
          <h2>Members</h2>
          {(user.userRole === "Admin" || user.userRole === "Super Admin") && (
            <FilledButton onClick={() => setShowAddModal(true)} endIcon={<Add />}>Add Member</FilledButton>
          )}
        </div>
        <CustomTextField label="Search" size='small' type='search' fullWidth={false}
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            )
          }}
        />
        {isLoading ? (<LinearProgress />) : (
          <div className="table-responsive">
            <table className="table table-hover mt-4 align-middle">
              <thead>
                <tr>
                  <th>Membership No</th>
                  <th>Name</th>
                  <th>NIC / Passport</th>
                  <th>Email</th>
                  <th>Mobile No</th>
                </tr>
              </thead>
              <tbody>
                {searchedMembers.map((member: IMember, index: number) =>
                  <tr
                    key={index} className="cursor-pointer"
                    onClick={() => { setSelectedMember(member); setShowViewEditModal(true) }}
                  >
                    <td>{member.membershipNo}</td>
                    <td>{member.title + ". " + member.firstname + " " + member.lastname}</td>
                    <td>{member.nic_passport}</td>
                    <td>{member.email}</td>
                    <td>{member.mobile1}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <ViewEditModal show={showViewEditModal} selectedMember={selectedMember} reloadMembers={() => getMembers()} onHide={() => closeViewEditModal()} />
      <AddMemberModal show={showAddModal} onHide={() => setShowAddModal(false)} />
    </>
  )
}

export default Members