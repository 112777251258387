import { ReactElement } from "react"
import Navbar from "../Navbar/Navbar"
import SideNavbar from "../SideNavbar/SideNavbar";

type LayoutProps = {
    children: ReactElement
}

export default function Layout(props: Readonly<LayoutProps>) {
    return (
        <main className="d-flex flex-row h-100">
            <SideNavbar />
            <div className="w-100">
                <Navbar />
                {props.children}
            </div>
        </main>
    )
}