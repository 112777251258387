import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CustomCircularProgress, DeleteButton, HollowButton } from '../../Components/CustomMui/CustomMui'
import { IDonation, IProject } from '../../Helpers/Interfaces';
import '../../Modal.css'
import axios from 'axios';
import { BaseUrl } from '../../Helpers/HelperFunctions';
import { toast } from 'react-toastify';

type DeleteProjectModalProps = {
  show: boolean;
  onHide: CallableFunction;
  project: IProject;
  reloadProjects: CallableFunction;
}

function DeleteProjectModal(props: Readonly<DeleteProjectModalProps>) {
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [isDonationsLoading, setIsDonationsLoading] = useState(false as boolean);
  const [donations, setDonations] = useState([] as IDonation[])
  const [showDeleteButton, setShowDeleteButton] = useState(false as boolean)

  useEffect(() => {
    if (props.project) {
      getDonationsByProject()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.project])

  async function getDonationsByProject() {
    setIsDonationsLoading(true)
    await axios.get(BaseUrl + 'donation/project/' + props.project._id).then((response) => {
      setDonations(response.data.result)
      setShowDeleteButton(response.data.result.length === 0)
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsDonationsLoading(false)
    })
  }

  const handleDeleteProject = async () => {
    setIsLoading(true)

    await axios.delete(BaseUrl + 'project/' + props.project._id).then((res) => {
      toast.success(res.data.message)
      props.reloadProjects();
      props.onHide();
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <Modal show={props.show} onHide={() => props.onHide()} centered>
      <Modal.Header closeButton><h3>Delete project</h3></Modal.Header>
      <Modal.Body>
        {isDonationsLoading ? (
          <div className='w-100 justify-center'>
            <CustomCircularProgress />
          </div>
        ) : donations.length > 0 ? (
          <p>There are contributions associated with this project. You cant delete a project having contributions try editing the project details</p>
        ) : (
          <p>Are you sure you want to delete <b>{props?.project?.name}</b>?</p>
        )}
      </Modal.Body>
      <Modal.Footer className='gap-2'>
        <HollowButton onClick={() => props.onHide()}>Cancel</HollowButton>
        <DeleteButton
          hidden={!showDeleteButton}
          onClick={() => handleDeleteProject()} endIcon={isLoading && <CustomCircularProgress />}
        >
          Delete
        </DeleteButton>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteProjectModal