import { ILoginUser, IMember } from "./Interfaces";
import AWS from "aws-sdk";
let CryptoJS = require("crypto-js");
let encryptKey = "secret key 123";
// export const BaseUrl = "http://localhost:8080/api/";
export const BaseUrl = "https://13.60.205.43/api/"

export function saveUser(user: ILoginUser) {
  let encryptedUser = encryptUser(user)
  sessionStorage.setItem("user", JSON.stringify(encryptedUser))
}

function encryptUser(user: ILoginUser) {
  return CryptoJS.AES.encrypt(JSON.stringify(user), encryptKey).toString();
}

export function getUser(): ILoginUser {
  return decryptUser();
}

function decryptUser() {
  let user = JSON.parse(sessionStorage.getItem("user") ?? "{}");

  let decryptedUser: ILoginUser = { _id: "", membershipNo: "", profilePic: "", email: "", firstname: "", lastname: "", userRole: "" };

  if (!isEmptyOrNullObj(user)) {
    let userBytes = CryptoJS.AES.decrypt(user, encryptKey);
    decryptedUser = JSON.parse(userBytes.toString(CryptoJS.enc.Utf8));
  }

  return decryptedUser;
}

export function saveToken(jwt: string) {
  let token = encryptToken(jwt);
  sessionStorage.setItem("token", JSON.stringify(token));
}

function encryptToken(jwt: string) {
  let jwtCipherText = CryptoJS.AES.encrypt(JSON.stringify(jwt), encryptKey).toString();
  return jwtCipherText
}

export function getToken() {
  let token = decryptToken();
  return token;
}

function decryptToken() {
  let token = JSON.parse(sessionStorage.getItem("token") ?? "{}");

  let jwt = "";

  if (!isEmptyOrNullObj(token)) {
    let jwtBytes = CryptoJS.AES.decrypt(token?.jwt, encryptKey);

    jwt = JSON.parse(jwtBytes.toString(CryptoJS.enc.Utf8));
  }

  return jwt
}


export function isEmptyOrNullObj(obj: object | undefined | null) {
  if (obj === null || obj === undefined) {
    return true;
  }

  return Object.keys(obj).length === 0;
}

export function isEmptyString(value: string | undefined | null) {
  if (value) {
    return false;
  }

  return true
}

export function removeTokens() {
  sessionStorage.removeItem("tokens");
  sessionStorage.removeItem("user");
  return true;
}

export function getSearchResults(keyword: string, data: any) {
  keyword = keyword.toLowerCase()

  return data.filter((item: any) =>
    item.membershipNo?.toLowerCase().includes(keyword) ||
    item.name?.toLowerCase().includes(keyword) ||
    item.firstname?.toLowerCase().includes(keyword) ||
    item.lastname?.toLowerCase().includes(keyword) ||
    item.mobile1?.toLowerCase().includes(keyword) ||
    item.email?.toLowerCase().includes(keyword) ||
    item.nic?.toLowerCase().includes(keyword)
  )
}

export function toBase64(file: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function uploadToS3(file: File, membershipNo: string, isProfilePhoto: boolean) {

  const fileName = isProfilePhoto ? "profile_photo." + file.name.split('.').pop() : file.name;

  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME as string;
  const REGION = process.env.REACT_APP_AWS_REGION;

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const params = {
    Bucket: S3_BUCKET,
    Key: membershipNo + "/" + fileName,
    Body: file,
  };

  return s3.putObject(params).promise().then(() => {
    return `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${membershipNo}/${fileName}`;
  }).catch((err) => {
    throw new Error(err);
  });
}

export function checkUserPrivileges(addedByUserRole: IMember["userRole"], approvedByUserRole: IMember["userRole"], addedBy: IMember["_id"], approvedBy: IMember["_id"]) {
  if (addedByUserRole === "Collaborator" && (approvedByUserRole === "Admin" || approvedByUserRole === "Super Admin")) {
    return true;
  } else if (addedByUserRole === "Admin" && approvedByUserRole === "Super Admin") {
    return true;
  } else if (addedByUserRole === "Super Admin" && approvedByUserRole === "Super Admin" && (approvedBy !== addedBy)) {
    return true;
  } else {
    return false;
  }
}