import { IconButton, InputAdornment, LinearProgress } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { CustomTextField, FilledButton } from '../../Components/CustomMui/CustomMui'
import { BaseUrl, getSearchResults } from '../../Helpers/HelperFunctions'
import { IProject } from '../../Helpers/Interfaces'
import { Add, Close, Edit, Search } from '@mui/icons-material';
import axios from 'axios'
import { toast } from 'react-toastify'
import { UserContext } from '../../Store/Store'
import { UserContextType } from '../../Helpers/Types'
import { useNavigate } from 'react-router-dom'
import DeleteProjectModal from './DeleteProjectModal'
import AddEditProjectModal from './AddEditProjectModal'

function Projects() {
  const { user } = useContext(UserContext) as UserContextType;
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [projects, setProjects] = useState([] as IProject[])
  const [searchedProjects, setSearchedProjects] = useState([] as IProject[])
  const [showAddEditModal, setShowAddEditModal] = useState(false as boolean)
  const navigate = useNavigate()
  const [showDeleteModal, setShowDeleteModal] = useState(false as boolean)
  const [selectedProject, setSelectedProject] = useState(null as IProject | null)

  useEffect(() => {
    getProjects()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getProjects() {
    setIsLoading(true)
    await axios.get(BaseUrl + 'project/').then((response) => {
      setProjects(response.data.result)
      setSearchedProjects(response.data.result)
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handleSearch = (searchKeyWord: string) => {
    const searchResults = getSearchResults(searchKeyWord, projects)
    setSearchedProjects(searchResults)
  }

  const editProject = (project: IProject) => {
    setSelectedProject(project)
    setShowAddEditModal(true)
  }

  const removeProject = async (project: IProject) => {
    setSelectedProject(project)
    setShowDeleteModal(true)
  }

  return (
    <>
      <div className='page-wrapper'>
        <div className='justify-between flex-wrap mb-3'>
          <h2>Projects</h2>
          {user.userRole === "Super Admin" && (
            <FilledButton onClick={() => setShowAddEditModal(true)} endIcon={<Add />}>Add Project</FilledButton>
          )}
        </div>
        <CustomTextField label="Search" size='small' type='search' fullWidth={false}
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            )
          }}
        />
        {isLoading ? (<LinearProgress />) : (
          <div className="table-responsive">
            <table className="table table-hover mt-4 align-middle">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Started Date</th>
                  <th>Estimated Amount</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {searchedProjects.map((project: IProject, index: number) =>
                  <tr key={index}>
                    <td className='cursor-pointer' onClick={() => navigate(`${project._id}`)}>{project.name}</td>
                    <td className='cursor-pointer' onClick={() => navigate(`${project._id}`)}>
                      {new Date(project.startDate).toLocaleDateString()}
                    </td>
                    <td className='cursor-pointer' onClick={() => navigate(`${project._id}`)}>
                      Rs {project.estimatedAmount.toFixed(2)}
                    </td>
                    <td><IconButton onClick={() => editProject(project)}><Edit /></IconButton></td>
                    <td><IconButton onClick={() => removeProject(project)}><Close /></IconButton></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <AddEditProjectModal
        show={showAddEditModal} onHide={() => { setShowAddEditModal(false); setSelectedProject(null) }}
        project={selectedProject} reloadProjects={() => getProjects()}
      />
      <DeleteProjectModal
        show={showDeleteModal} onHide={() => { setShowDeleteModal(false); setSelectedProject(null) }}
        project={selectedProject as IProject} reloadProjects={() => getProjects()}
      />
    </>
  )
}

export default Projects