import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CustomCircularProgress, CustomDatePicker, CustomTextField, FilledButton, HollowButton } from '../../Components/CustomMui/CustomMui'
import { IMember } from '../../Helpers/Interfaces';
import '../../Modal.css'
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { DesignationTypes, UserContextType } from '../../Helpers/Types';
import axios from 'axios';
import { BaseUrl } from '../../Helpers/HelperFunctions';
import { toast } from 'react-toastify';
import { Dayjs } from 'dayjs';
import { UserContext } from '../../Store/Store';

type AddCommitteeModalProps = {
  show: boolean;
  onHide: CallableFunction;
  reloadCommitteeMembers: CallableFunction;
}

function AddCommitteeModal(props: Readonly<AddCommitteeModalProps>) {
  const { user } = useContext(UserContext) as UserContextType;
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [members, setMembers] = useState([] as IMember[]);
  const [member, setMember] = useState(null as IMember | null);
  const [designation, setDesignation] = useState("" as DesignationTypes);
  const [dateAppointed, setDateAppointed] = useState(null as Dayjs | null);

  useEffect(() => {
    getMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getMembers() {
    setIsLoading(true)
    await axios.get(BaseUrl + 'member').then((response) => {
      setMembers(response.data.result)
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handleAddCommittee = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    const data = {
      memberId: member?._id,
      designation,
      dateAppointed,
      addedBy: user._id
    }

    await axios.post(BaseUrl + 'committee/', data).then((res) => {
      toast.success(res.data.message)
      clearFields()
      props.reloadCommitteeMembers();
      closeModal()
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  function closeModal() {
    clearFields();
    props.onHide();
  }

  function clearFields() {
    setMember(null);
    setDesignation("" as DesignationTypes);
    setDateAppointed(null);
  }

  return (
    <Modal show={props.show} onHide={() => closeModal()} centered>
      <Modal.Header closeButton>
        <h3>Add Committee Member</h3>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleAddCommittee}>
          <Autocomplete
            options={members}
            getOptionLabel={(option) => option.membershipNo + " - " + option.firstname + " " + option.lastname}
            value={member}
            onChange={(event, newValue) => setMember(newValue)}
            renderInput={(params) => (
              <TextField
                {...params} required fullWidth
                label="Member" margin='normal' variant="outlined"
              />
            )}
          />
          <CustomTextField select label="Designation" value={designation} onChange={(e) => setDesignation(e.target.value as DesignationTypes)}>
            <MenuItem value="Most Venerable Rev.Patron">Most Venerable Rev.Patron</MenuItem>
            <MenuItem value="Patron">Patron</MenuItem>
            <MenuItem value="Advisor">Advisor</MenuItem>
            <MenuItem value="President">President</MenuItem>
            <MenuItem value="Vice President">Vice President</MenuItem>
            <MenuItem value="Joint Secretary">Joint Secretary</MenuItem>
            <MenuItem value="Treasurer">Treasurer</MenuItem>
            <MenuItem value="Vice Treasurer">Vice Treasurer</MenuItem>
            <MenuItem value="Secretary of Social Affairs">Secretary of Social Affairs</MenuItem>
            <MenuItem value="Editor">Editor</MenuItem>
            <MenuItem value="Legal Advisor">Legal Advisor</MenuItem>
            <MenuItem value="Executive Member">Executive Member</MenuItem>
          </CustomTextField>
          <CustomDatePicker label="Date Appointed" value={dateAppointed}
            onChange={(newValue: React.SetStateAction<Dayjs | null>) => setDateAppointed(newValue)}
          />
          <div className='text-end mt-5'>
            <HollowButton onClick={() => closeModal()}>Cancel</HollowButton>
            <FilledButton type='submit' className='ms-3'>{isLoading ? <CustomCircularProgress /> : "Save"}</FilledButton>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AddCommitteeModal