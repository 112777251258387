import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CustomAutoComplete, CustomCircularProgress, CustomDatePicker, CustomTextField, FilledButton, HollowButton } from '../../../Components/CustomMui/CustomMui'
import { IDonation, IMember, IProject } from '../../../Helpers/Interfaces';
import '../../../Modal.css'
import { Button } from '@mui/material';
import axios from 'axios';
import { BaseUrl } from '../../../Helpers/HelperFunctions';
import { toast } from 'react-toastify';
import { UserContextType } from '../../../Helpers/Types';
import { UserContext } from '../../../Store/Store';
import dayjs, { Dayjs } from 'dayjs';

type AddEditDonationModalProps = {
  show: boolean;
  onHide: CallableFunction;
  reloadDonations: CallableFunction;
  projectId: IProject["_id"];
  donation: IDonation | null;
}

function AddEditDonationModal(props: Readonly<AddEditDonationModalProps>) {
  const [contributionType, setContributionType] = useState("Financial" as IDonation["contributionType"]);
  const { user } = useContext(UserContext) as UserContextType;
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [isMembersLoading, setIsMembersLoading] = useState(false as boolean);
  const [members, setMembers] = useState([] as IMember[]);
  const [member, setMember] = useState(null as IMember | null);
  const [description, setDescription] = useState('' as string);
  const [amount, setAmount] = useState(0 as number);
  const [dateDonated, setDateDonated] = useState(null as Dayjs | null);

  useEffect(() => {
    if (props.donation) {
      setMember(props.donation.memberId)
      setContributionType(props.donation.contributionType)
      setDescription(props.donation.description)
      setAmount(props.donation.amount)
      setDateDonated(dayjs(props.donation.dateDonated))
    } else {
      clearFields()
    }
    getMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.donation])

  async function getMembers() {
    setIsMembersLoading(true)
    await axios.get(BaseUrl + 'member').then((response) => {
      setMembers(response.data.result)
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsMembersLoading(false)
    })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    props.donation ? handleUpdateDonation() : handleAddDonation()
  }

  const handleAddDonation = async () => {
    setIsLoading(true)

    let data = {
      memberId: member?._id,
      projectId: props.projectId,
      dateDonated,
      contributionType,
      description,
      amount: contributionType === "Financial" ? amount : 0,
      addedBy: user._id
    }

    await axios.post(BaseUrl + 'donation/', data).then((res) => {
      toast.success(res.data.message)
      clearFields()
      props.reloadDonations();
      closeModal()
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handleUpdateDonation = async () => {
    setIsLoading(true)

    let data = {
      memberId: member?._id,
      dateDonated,
      contributionType,
      description,
      amount: contributionType === "Financial" ? amount : 0,
      updatedBy: user._id
    }

    await axios.put(BaseUrl + 'donation/edit/' + props.donation?._id, data).then((res) => {
      toast.success(res.data.message)
      clearFields()
      props.reloadDonations();
      closeModal()
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  function closeModal() {
    clearFields();
    props.onHide();
  }

  function clearFields() {
    setMember(null);
    setDateDonated(null)
    setAmount(0);
    setDescription('');
  }

  return (
    <Modal show={props.show} onHide={() => closeModal()} centered>
      <Modal.Header closeButton>
        <h3>{props.donation ? "Edit" : "Add"} donation</h3>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit} id='addEditDonation'>
          <CustomAutoComplete
            label="Member" options={members} value={member}
            getOptionLabel={(option: IMember) => option.membershipNo + " - " + option.firstname + " " + option.lastname}
            onChange={(event: React.SyntheticEvent<Element, Event>, newValue: IMember) => setMember(newValue)}
            isLoading={isMembersLoading}
            readOnly={props.donation}
          />
          <CustomDatePicker
            label="Date donated" value={dateDonated} defaultValue={props.donation ? dayjs(props.donation.dateDonated) : null}
            onChange={(newValue: React.SetStateAction<Dayjs | null>) => setDateDonated(newValue)}
          />
          <div className='d-flex my-3 justify-content-center'>
            <Button className={contributionType === "Financial" ? "active-tab" : "tab"} onClick={() => setContributionType("Financial")}>
              Financial
            </Button>
            <Button className={contributionType === "Physical" ? "active-tab" : "tab"} onClick={() => setContributionType("Physical")}>
              Physical
            </Button>
          </div>
          {contributionType === "Financial" && (
            <CustomTextField
              type='number' label="Amount" value={amount} onChange={(e) => setAmount(parseFloat(e.target.value))}
              required={contributionType === "Financial"}
              InputProps={{ inputProps: { min: 10 } }}
            />
          )}
          <CustomTextField type='text' label="Description" required={false} value={description} onChange={(e) => setDescription(e.target.value)} />
        </form>
      </Modal.Body>
      <Modal.Footer className='gap-1'>
        <HollowButton onClick={() => closeModal()}>Cancel</HollowButton>
        <FilledButton type='submit' form='addEditDonation' endIcon={isLoading && <CustomCircularProgress />}>
          {props.donation ? "Save" : "Donate"}
        </FilledButton>
      </Modal.Footer>
    </Modal>
  )
}

export default AddEditDonationModal