import React, { useContext, useEffect, useState } from 'react'
import { IMember } from '../../Helpers/Interfaces';
import axios from 'axios';
import { BaseUrl, checkUserPrivileges } from '../../Helpers/HelperFunctions';
import { toast } from 'react-toastify';
import { UserContext } from '../../Store/Store';
import { UserContextType } from '../../Helpers/Types';
import { LinearProgress } from '@mui/material';
import ViewEditModal from '../Members/ViewEditModal';
import { CustomCircularProgress, FilledButton } from '../../Components/CustomMui/CustomMui';

function PendingMembers() {
  const { user } = useContext(UserContext) as UserContextType
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [isApproveLoading, setIsApproveLoading] = useState(false as boolean);
  const [pendingMembers, setPendingMembers] = useState([] as IMember[]);
  const [selectedMember, setSelectedMember] = useState(null as IMember | null);
  const [showViewEditModal, setShowViewEditModal] = useState(false as boolean);

  useEffect(() => {
    getPendingMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getPendingMembers() {
    setIsLoading(true)
    await axios.get(BaseUrl + 'member/pending').then((response) => {
      setPendingMembers(response.data.result)
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  async function approveMember(member: IMember) {
    const checkApproveUserRole = checkUserPrivileges(member.addedBy.userRole, user.userRole, member.addedBy._id, user._id);

    if (!checkApproveUserRole) {
      return toast.error("You are not authorized to approve this donation")
    }

    const data = {
      id: member._id,
      approvedBy: user._id
    }
    setIsApproveLoading(true)
    await axios.put(BaseUrl + 'member/approve', data).then(() => {
      getPendingMembers()
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsApproveLoading(false)
    })
  }

  function closeViewEditModal() {
    setShowViewEditModal(false)
    setSelectedMember(null)
  }

  return (
    <div>
      <h3>Pending Members</h3>
      {isLoading ? (
        <LinearProgress />
      ) : pendingMembers.length === 0 ? (
        <p>No pending members</p>
      ) : (
        <div className="table-responsive pt-4 px-4" style={{ borderRadius: '8px', background: 'white' }}>
          <table className="table align-middle">
            <thead>
              <tr>
                <th>Membership No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile No</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {pendingMembers.map((member: IMember, index: number) =>
                <tr key={index} className='cursor-pointer'>
                  <td onClick={() => { setSelectedMember(member); setShowViewEditModal(true) }}>{member.membershipNo}</td>
                  <td onClick={() => { setSelectedMember(member); setShowViewEditModal(true) }}>
                    {member.title + ". " + member.firstname + " " + member.lastname}
                  </td>
                  <td onClick={() => { setSelectedMember(member); setShowViewEditModal(true) }}>{member.email}</td>
                  <td onClick={() => { setSelectedMember(member); setShowViewEditModal(true) }}>{member.mobile1}</td>
                  <td>
                    <FilledButton
                      onClick={() => approveMember(member)}
                      endIcon={isApproveLoading && <CustomCircularProgress />}
                    >
                      Approve
                    </FilledButton>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      <ViewEditModal show={showViewEditModal} selectedMember={selectedMember}
        reloadMembers={() => getPendingMembers()}
        onHide={() => closeViewEditModal()}
      />
    </div>
  )
}

export default PendingMembers