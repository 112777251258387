import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CustomCircularProgress, CustomDatePicker, CustomTextField, FilledButton, HollowButton } from '../../Components/CustomMui/CustomMui'
import '../../Modal.css'
import axios from 'axios';
import { BaseUrl } from '../../Helpers/HelperFunctions';
import { toast } from 'react-toastify';
import { UserContextType } from '../../Helpers/Types';
import { UserContext } from '../../Store/Store';
import dayjs, { Dayjs } from 'dayjs';
import { IProject } from '../../Helpers/Interfaces';

type AddEditProjectModalProps = {
  show: boolean;
  project: IProject | null;
  onHide: CallableFunction;
  reloadProjects: CallableFunction;
}

function AddEditProjectModal(props: Readonly<AddEditProjectModalProps>) {
  const { user } = useContext(UserContext) as UserContextType;
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [name, setName] = useState("" as string);
  const [estimatedAmount, setEstimatedAmount] = useState(0 as number);
  const [startDate, setStartDate] = useState(null as Dayjs | null);

  useEffect(() => {
    if (props.project) {
      setName(props.project.name);
      setEstimatedAmount(props.project.estimatedAmount);
      setStartDate(dayjs(props.project.startDate));
    } else {
      clearFields();
    }
  }, [props.project])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    props.project ? handleUpdateProject() : handleAddProject()
  }

  const handleAddProject = async () => {
    setIsLoading(true)

    const data = {
      name,
      estimatedAmount,
      addedBy: user._id,
      startDate
    }

    await axios.post(BaseUrl + 'project/', data).then((res) => {
      toast.success(res.data.message)
      props.reloadProjects();
      closeModal()
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handleUpdateProject = async () => {
    setIsLoading(true)

    const data = {
      name,
      estimatedAmount,
      startDate
    }

    await axios.put(BaseUrl + 'project/' + props.project?._id, data).then((res) => {
      toast.success(res.data.message)
      props.reloadProjects();
      closeModal()
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  function closeModal() {
    clearFields();
    props.onHide();
  }

  function clearFields() {
    setName("");
    setEstimatedAmount(0);
    setStartDate(null);
  }

  return (
    <Modal show={props.show} onHide={() => closeModal()} centered>
      <Modal.Header closeButton>
        <h3>{props.project ? "Edit Project" : "Add Project"}</h3>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit} id="addEditProject">
          <CustomTextField type='text' label="Name" value={name} onChange={(e) => setName(e.target.value)} />
          <CustomTextField
            type='number' label="Estimated amount" value={estimatedAmount} onChange={(e) => setEstimatedAmount(parseFloat(e.target.value))}
            InputProps={{ inputProps: { min: 10 } }}
          />
          <CustomDatePicker
            label="Start date" value={startDate} defaultValue={props.project ? dayjs(props.project.startDate) : null}
            onChange={(newValue: React.SetStateAction<Dayjs | null>) => setStartDate(newValue)}
          />
        </form>
      </Modal.Body>
      <Modal.Footer className='gap-1'>
        <HollowButton onClick={() => closeModal()}>Cancel</HollowButton>
        <FilledButton type='submit' form="addEditProject" endIcon={isLoading && <CustomCircularProgress />}>
          {props.project ? "Update" : "Add"}
        </FilledButton>
      </Modal.Footer>
    </Modal>
  )
}

export default AddEditProjectModal