import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CustomCircularProgress, CustomTextField, FilledButton, HollowButton } from '../../Components/CustomMui/CustomMui'
import { IMember } from '../../Helpers/Interfaces';
import '../../Modal.css'
import { MenuItem } from '@mui/material';
import { UserRoleTypes } from '../../Helpers/Types';
import axios from 'axios';
import { BaseUrl } from '../../Helpers/HelperFunctions';
import { toast } from 'react-toastify';

type ChangeUserRoleModalProps = {
  show: boolean;
  onHide: CallableFunction;
  admin: IMember;
  reloadAdmins: CallableFunction;
}

function ChangeUserRoleModal(props: Readonly<ChangeUserRoleModalProps>) {
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [userRole, setUserRole] = useState("" as UserRoleTypes);

  useEffect(() => {
    setUserRole(props.admin.userRole)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show])

  const handleUpdateUserRole = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    if (props.admin.userRole === userRole) {
      toast.error("This user is already a " + userRole)
      setIsLoading(false)
      return
    }

    const data = {
      id: props.admin._id,
      userRole,
    }

    await axios.put(BaseUrl + 'member/change-admin-role', data).then((res) => {
      toast.success(res.data.message)
      props.reloadAdmins();
      closeModal()
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  function closeModal() {
    setUserRole("");
    props.onHide();
  }

  return (
    <Modal show={props.show} onHide={() => closeModal()} centered>
      <Modal.Header closeButton>
        <h3>Change Privileges</h3>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleUpdateUserRole} id="updateUserRoleForm">
          <p><b>{props.admin.title + ". " + props.admin.firstname + " " + props.admin.lastname}</b> is a <b>{props.admin.userRole}</b> of the system and please select the new user role you want to assign him.</p>
          <small>He will be notified through email automatically about this action.</small>
          <CustomTextField className='mt-4' select label="User Role" value={userRole} onChange={(e) => setUserRole(e.target.value as UserRoleTypes)} >
            <MenuItem value='Super Admin'>Super Admin</MenuItem>
            <MenuItem value='Admin'>Admin</MenuItem>
            <MenuItem value='Collaborator'>Collaborator</MenuItem>
          </CustomTextField>
        </form>
      </Modal.Body>
      <Modal.Footer className='gap-2'>
        <HollowButton onClick={() => closeModal()}>Cancel</HollowButton>
        <FilledButton form="updateUserRoleForm" type='submit' endIcon={isLoading && <CustomCircularProgress />}>Update</FilledButton>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangeUserRoleModal