import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CustomAutoComplete, CustomCircularProgress, CustomTextField, FilledButton, HollowButton } from '../../Components/CustomMui/CustomMui'
import { IMember } from '../../Helpers/Interfaces';
import '../../Modal.css'
import { IconButton, InputAdornment, MenuItem } from '@mui/material';
import { UserRoleTypes } from '../../Helpers/Types';
import axios from 'axios';
import { BaseUrl } from '../../Helpers/HelperFunctions';
import { toast } from 'react-toastify';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type AddAdminModalProps = {
  show: boolean;
  onHide: CallableFunction;
  adminIds: string[];
  reloadAdmins: CallableFunction;
}

function AddAdminModal(props: Readonly<AddAdminModalProps>) {
  const [isMembersLoading, setIsMembersLoading] = useState(false as boolean);
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [members, setMembers] = useState([] as IMember[]);
  const [member, setMember] = useState(null as IMember | null);
  const [userRole, setUserRole] = useState("" as UserRoleTypes);
  const [password, setPassword] = useState("" as string);
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    getMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show])

  async function getMembers() {
    setIsMembersLoading(true)
    await axios.get(BaseUrl + 'member').then((response) => {
      response.data.result = response.data.result.filter((member: IMember) => !props.adminIds.includes(member._id))
      setMembers(response.data.result)
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsMembersLoading(false)
    })
  }

  const handleAddAdmin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    if (member?.userRole === userRole) {
      toast.error("This user is already a " + userRole)
      setIsLoading(false)
      return
    }

    const data = {
      id: member?._id,
      userRole,
      password
    }

    await axios.put(BaseUrl + 'member/admin', data).then((res) => {
      toast.success(res.data.message)
      clearFields()
      props.reloadAdmins();
      closeModal()
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  function closeModal() {
    clearFields();
    props.onHide();
  }

  function clearFields() {
    setMember(null);
    setUserRole("");
  }

  return (
    <Modal show={props.show} onHide={() => closeModal()} centered>
      <Modal.Header closeButton>
        <h3>Add admin</h3>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleAddAdmin} id="addAdminForm">
          <CustomAutoComplete
            options={members}
            getOptionLabel={(option: IMember) => option.membershipNo + " - " + option.firstname + " " + option.lastname}
            value={member}
            onChange={(event: React.SyntheticEvent<Element, Event>, newValue: IMember) => setMember(newValue)}
            label="Member"
            isLoading={isMembersLoading}
          />
          <CustomTextField select label="User Role" value={userRole} onChange={(e) => setUserRole(e.target.value as UserRoleTypes)} >
            <MenuItem value='Super Admin'>Super Admin</MenuItem>
            <MenuItem value='Admin'>Admin</MenuItem>
            <MenuItem value='Collaborator'>Collaborator</MenuItem>
          </CustomTextField>
          <CustomTextField
            label='Password' type={showPassword ? "text" : "password"} inputProps={{ pattern: '^(.{8,})$' }}
            value={password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword((showPassword) => !showPassword)} >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            onChange={(e) => setPassword(e.target.value)}
          />
        </form>
      </Modal.Body>
      <Modal.Footer className='gap-2'>
        <HollowButton onClick={() => closeModal()}>Cancel</HollowButton>
        <FilledButton form="addAdminForm" type='submit' endIcon={isLoading && <CustomCircularProgress />}>Save</FilledButton>
      </Modal.Footer>
    </Modal>
  )
}

export default AddAdminModal