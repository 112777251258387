import { Autocomplete, Button, ButtonProps, Checkbox, CheckboxProps, CircularProgress, TextField, TextFieldProps } from '@mui/material';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import './CustomMui.css'

export function FilledButton(props: ButtonProps) {
  return (
    <Button
      disableElevation size='large' variant='contained' className={props.className + ' primary-btn' || 'primary-btn'}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export function HollowButton(props: ButtonProps) {
  return (
    <Button
      disableElevation className={props.className + ' secondary-btn' || 'secondary-btn'} variant='outlined' size='large'
      {...props}
    >
      {props.children}
    </Button>
  );
};

export function DeleteButton(props: ButtonProps) {
  return (
    <Button
      disableElevation className={props.className + ' delete-btn' || 'delete-btn'} variant='contained' size='large'
      {...props}
    >
      {props.children}
    </Button>
  );
};


export function CustomTextField(props: TextFieldProps) {
  return (
    <TextField
      fullWidth={props.fullWidth !== false} required={props.required !== false} variant='outlined' margin='normal'
      {...props}
    >
      {props.children}
    </TextField>
  );
};

export function CustomCheckBox(props: Readonly<CheckboxProps>) {
  return (
    <Checkbox size='small' checked={props.checked} onChange={props.onChange} icon={<CheckBoxOutlineBlankOutlinedIcon className='checkbox' />}
      checkedIcon={<SquareRoundedIcon className='checkbox-check' />} />
  );
};

export function CustomDatePicker(props: any) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker slotProps={{ textField: { fullWidth: true, margin: "normal", required: props.required !== false, size: props.size } }} disableFuture label={props.label} className={props.className} onChange={props.onChange} views={props.views} defaultValue={props.defaultValue} />
    </LocalizationProvider>
  );
};

export function CustomCircularProgress() {
  return (
    <CircularProgress color='inherit' size={24} />
  );
};

export function CustomAutoComplete(props: any) {
  return (
    <Autocomplete
      {...props}
      renderInput={(params) => (
        <CustomTextField
          {...params} label={props.label}
          InputProps={{
            ...params.InputProps,
            endAdornment: props.isLoading ? <CustomCircularProgress /> : params.InputProps.endAdornment
          }}
        />
      )}
    />
  );
};