import DashboardCard from './DashboardCard';
import './Dashboard.css';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { BaseUrl, checkUserPrivileges } from '../../Helpers/HelperFunctions';
import { ICommittee, IMember } from '../../Helpers/Interfaces';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FilledButton } from '../../Components/CustomMui/CustomMui';
import { CloudDownload } from '@mui/icons-material';
import * as XLSX from "xlsx";
import dayjs from 'dayjs'
import { CircularProgress, LinearProgress } from '@mui/material';
import { UserContextType } from '../../Helpers/Types';
import { UserContext } from '../../Store/Store';
import PendingMembers from './PendingMembers';
import PendingContributions from './PendingContributions';

function Dashboard() {
  const { user } = useContext(UserContext) as UserContextType
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [isPendingCommitteeLoading, setIsPendingCommitteeLoading] = useState(false as boolean);
  const [members, setMembers] = useState([] as IMember[]);
  const [pendingCommittee, setPendingCommittee] = useState([] as ICommittee[]);
  const [memberCount, setMemberCount] = useState(0 as number);
  const [expiredMemberCount, setExpiredMemberCount] = useState(0 as number);
  const [juniorMemberCount, setJuniorMemberCount] = useState(0 as number);
  const [newMemberCount, setNewMemberCount] = useState(0 as number);

  useEffect(() => {
    getMembers()
    getPendingCommittee()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getMembers() {
    setIsLoading(true)
    await axios.get(BaseUrl + 'member').then((response) => {
      const membersData = response.data.result
      setMembers(membersData)
      const currentDate = moment();
      setMemberCount(membersData.length)
      setJuniorMemberCount(0)
      setExpiredMemberCount(membersData.filter((member: IMember) => member.status === "Deceased" || member.status === "Suspended").length);
      setNewMemberCount(membersData.filter((member: IMember) => moment(member.createdAt).isAfter(currentDate.subtract(1, 'year'))).length);
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  async function getPendingCommittee() {
    setIsPendingCommitteeLoading(true)
    await axios.get(BaseUrl + 'committee/pending').then((response) => {
      setPendingCommittee(response.data.result)
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsPendingCommitteeLoading(false)
    })
  }

  async function approveCommittee(committee: ICommittee) {
    const checkApproveUserRole = checkUserPrivileges(committee.addedBy.userRole, user.userRole, committee.addedBy._id, user._id);

    if (!checkApproveUserRole) {
      return toast.error("You are not authorized to approve this committee member")
    }

    const data = {
      id: committee._id,
      approvedBy: user._id
    }
    setIsLoading(true)
    await axios.put(BaseUrl + 'committee/approve', data).then((res) => {
      toast.success(res.data.message);
      getPendingCommittee()
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  function downloadExcel() {

    const excelMembers = members.map(member => ({
      membershipNo: member.membershipNo,
      proposerNo: member.proposer.membershipNo,
      seconderNo: member.seconder.membershipNo,
      joinedDate: dayjs(member.joinedDate).format('DD-MM-YYYY'),
      title: member.title,
      firstname: member.firstname,
      lastname: member.lastname,
      otherName: member.otherName,
      dob: dayjs(member.dob).format('DD-MM-YYYY'),
      nic_passport: member.nic_passport,
      mobile1: member.mobile1,
      mobile2: member.mobile2,
      landline: member.landline,
      fax: member.fax,
      address: member.address,
      postalCode: member.postalCode,
      city: member.city,
      isLocalMailing: member.isLocalMailing,
      isForeignResident: member.isForeignResident,
      foreignAddress: member.foreignAddress,
      foreignPostalCode: member.foreignPostalCode,
      foreignCity: member.foreignCity,
      country: member.country,
      isForeignMailing: member.isForeignMailing,
      occupation: member.occupation,
      company: member.company,
      companyAddress: member.companyAddress,
      companyPostalCode: member.companyPostalCode,
      companyCity: member.companyCity,
      companyCountry: member.companyCountry,
      indexNo: member.indexNo,
      admissionYear: dayjs(member.admissionYear).format('YYYY'),
      leavingYear: dayjs(member.leavingYear).format('YYYY'),
      branch: member.branch,
      juniorTransferDate: dayjs(member.juniorTransferDate).format('DD-MM-YYYY'),
      userRole: member.userRole,
      status: member.status,
    }));

    const worksheet = XLSX.utils.json_to_sheet(excelMembers);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `Members-${new Date()}.xlsx`);
  };

  return (
    <div className='page-wrapper-transparent'>
      <div className='dashboard-grid'>
        <DashboardCard title='Members' isLoading={isLoading} value={memberCount} />
        <DashboardCard title='Expired Members' isLoading={isLoading} value={expiredMemberCount} />
        <DashboardCard title='Junior Members' isLoading={isLoading} value={juniorMemberCount} />
        <DashboardCard title='New Members' isLoading={isLoading} value={newMemberCount} />
        {user.userRole !== "Collaborator" && (
          <FilledButton disabled={isLoading} endIcon={<CloudDownload />} onClick={() => downloadExcel()}>
            Download Members Excel Report
          </FilledButton>
        )}
      </div>
      <div className='mt-5'>
        <PendingMembers />
      </div>
      <div className='mt-5'>
        <PendingContributions />
      </div>
      <div className='mt-5'>
        <h3>Pending Committee members</h3>
        {isPendingCommitteeLoading ? <LinearProgress /> :
          pendingCommittee.length > 0 ? (
            <div className="table-responsive pt-4 px-4" style={{ borderRadius: '8px', background: 'white' }}>
              <table className="table align-middle">
                <thead>
                  <tr>
                    <th>Membership No</th>
                    <th>Name</th>
                    <th>Designation</th>
                    <th>Date Appointed</th>
                    <th>Added By</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {pendingCommittee.map((committee: ICommittee, index: number) =>
                    <tr key={index}>
                      <td>{committee.memberId.membershipNo}</td>
                      <td>{committee.memberId.title + ". " + committee.memberId.firstname + " " + committee.memberId.lastname}</td>
                      <td>{committee.designation}</td>
                      <td>{new Date(committee.dateAppointed).toLocaleDateString()}</td>
                      <td>{committee.addedBy.firstname + " " + committee.addedBy.lastname}</td>
                      <td>
                        <FilledButton onClick={() => approveCommittee(committee)}>
                          {isLoading ? <CircularProgress size={24} color="inherit" /> : "Approve"}
                        </FilledButton>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No pending committee members</p>
          )}
      </div>
    </div>
  )
}

export default Dashboard