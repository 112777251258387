import { useProSidebar } from 'react-pro-sidebar'
import { Avatar, IconButton } from '@mui/material'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useContext } from 'react';
import { UserContext } from '../../Store/Store';
import { UserContextType } from '../../Helpers/Types';

function Navbar() {
  const { toggleSidebar } = useProSidebar();
  const { user } = useContext(UserContext) as UserContextType;

  return (
    <nav className="navbar navbar-expand-lg sticky-top" style={{ background: 'var(--foreground)' }}>
      <div className='container-fluid'>
        <button className="navbar-toggler" onClick={() => toggleSidebar()}>
          <KeyboardDoubleArrowRightIcon style={{ color: 'var(--primary)' }} />
        </button>
        <div className='ms-auto justify-between'>
          <div className='text-end me-2'>
            <b>{user.firstname + " " + user.lastname}</b><br />
            <small>{user.userRole}</small>
          </div>
          <IconButton disabled>
            <Avatar src={user.profilePic} />
          </IconButton>
        </div>
      </div>
    </nav>
  )
}

export default Navbar