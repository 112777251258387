import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CustomCircularProgress, DeleteButton, HollowButton } from '../../../Components/CustomMui/CustomMui'
import '../../../Modal.css'
import axios from 'axios';
import { toast } from 'react-toastify';
import { UserContext } from '../../../Store/Store';
import { UserContextType } from '../../../Helpers/Types';
import { BaseUrl } from '../../../Helpers/HelperFunctions';
import { IDonation } from '../../../Helpers/Interfaces';

type DeleteDonationModalProps = {
  show: boolean;
  onHide: CallableFunction;
  donation: IDonation;
  reloadDonations: CallableFunction;
}

function DeleteDonationModal(props: Readonly<DeleteDonationModalProps>) {
  const { user } = useContext(UserContext) as UserContextType;
  const [isLoading, setIsLoading] = useState(false as boolean);

  const handleDeleteDonation = async () => {
    setIsLoading(true)
    const data = {
      donationId: props.donation._id,
      requestedBy: user._id
    }

    await axios.put(BaseUrl + 'donation/delete/', data).then((res) => {
      toast.success(res.data.message)
      props.reloadDonations();
      props.onHide();
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <Modal show={props.show} onHide={() => props.onHide()} centered>
      <Modal.Header closeButton><h3>Delete contribution</h3></Modal.Header>
      <Modal.Body>
        <p>Once you pressed the delete button it will send out for approval. Are you sure you want to delete this contribution?</p>
      </Modal.Body>
      <Modal.Footer className='gap-2'>
        <HollowButton onClick={() => props.onHide()}>Cancel</HollowButton>
        <DeleteButton
          onClick={() => handleDeleteDonation()} endIcon={isLoading && <CustomCircularProgress />}
        >
          Delete
        </DeleteButton>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteDonationModal