import { createContext, useEffect, useState } from "react";
import { UserContextType } from "../Helpers/Types";
import { ILoginUser } from "../Helpers/Interfaces";
import { getUser, isEmptyString } from "../Helpers/HelperFunctions";

export const UserContext = createContext<UserContextType | null>(null);

export default function Store({ children }: any) {

	const [user, setUser] = useState<ILoginUser>({ _id:"", membershipNo: "", profilePic: "", email: "", firstname: "", lastname: "",  userRole: "" });

	useEffect(() => {
		if (isEmptyString(user.firstname)) {

			let user = getUser();

			if (!isEmptyString(user.firstname)) {
				setUser(user);
			}
		}
	}, [user])

	function updateUser(newUser: ILoginUser) {
		setUser(newUser);
	}

	return (
		<UserContext.Provider value={{ user, updateUser }}>
			{children}
		</UserContext.Provider>
	);
}
