import React, { useContext, useEffect, useState } from 'react'
import { IDonation } from '../../Helpers/Interfaces';
import axios from 'axios';
import { BaseUrl, checkUserPrivileges } from '../../Helpers/HelperFunctions';
import { toast } from 'react-toastify';
import { UserContext } from '../../Store/Store';
import { UserContextType } from '../../Helpers/Types';
import { LinearProgress } from '@mui/material';
import { CustomCircularProgress, FilledButton } from '../../Components/CustomMui/CustomMui';
import ViewDonationModal from '../Projects/Donations/ViewDonationModal';

function PendingContributions() {
  const { user } = useContext(UserContext) as UserContextType
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [isApproveLoading, setIsApproveLoading] = useState({ id: '', isLoading: false } as { id: string, isLoading: boolean });
  const [pendingDonations, setPendingDonations] = useState([] as IDonation[]);
  const [pendingEditsDonations, setPendingEditsDonations] = useState([] as IDonation[]);
  const [pendingDeletesDonations, setPendingDeletesDonations] = useState([] as IDonation[]);
  const [selectedDonation, setSelectedDonation] = useState(null as IDonation | null);
  const [showViewModal, setShowViewModal] = useState(false as boolean);

  useEffect(() => {
    getPendingDonations()
    getPendingEditsDonations()
    getPendingDeleteDonations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getPendingDonations() {
    setIsLoading(true)
    await axios.get(BaseUrl + 'donation/pending').then((response) => {
      setPendingDonations(response.data.result)
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  async function getPendingEditsDonations() {
    setIsLoading(true)
    await axios.get(BaseUrl + 'donation/pending_edits').then((response) => {
      setPendingEditsDonations(response.data.result)
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  async function getPendingDeleteDonations() {
    setIsLoading(true)
    await axios.get(BaseUrl + 'donation/pending_deletes').then((response) => {
      setPendingDeletesDonations(response.data.result)
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  async function approveDonation(donation: IDonation) {
    const checkApproveUserRole = checkUserPrivileges(donation.addedBy.userRole, user.userRole, donation.addedBy._id, user._id);

    if (!checkApproveUserRole) {
      return toast.error("You are not authorized to approve this donation")
    }

    const data = {
      id: donation._id,
      approvedBy: user._id
    }

    setIsApproveLoading({ id: donation._id, isLoading: true })
    await axios.put(BaseUrl + 'donation/approve', data).then((res) => {
      toast.success(res.data.message);
      getPendingDonations()
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsApproveLoading({ id: donation._id, isLoading: false })
    })
  }

  async function approveEditDonation(donation: IDonation) {
    const lastChange = donation.changes[donation.changes.length - 1]
    const checkApproveUserRole = checkUserPrivileges(lastChange.updatedBy.userRole, user.userRole, lastChange.updatedBy._id, user._id);

    if (!checkApproveUserRole) {
      return toast.error("You are not authorized to approve this donation")
    }

    const data = {
      id: donation._id,
      approvedBy: user._id
    }

    setIsApproveLoading({ id: donation._id, isLoading: true })
    await axios.put(BaseUrl + 'donation/approve_edits', data).then((res) => {
      toast.success(res.data.message);
      getPendingEditsDonations()
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsApproveLoading({ id: donation._id, isLoading: false })
    })
  }

  async function approveDeleteDonation(donation: IDonation) {
    const lastChange = donation.changes[donation.changes.length - 1]
    const checkApproveUserRole = checkUserPrivileges(lastChange.updatedBy.userRole, user.userRole, lastChange.updatedBy._id, user._id);

    if (!checkApproveUserRole) {
      return toast.error("You are not authorized to delete this donation")
    }

    const data = {
      id: donation._id,
      approvedBy: user._id
    }

    setIsApproveLoading({ id: donation._id, isLoading: true })
    await axios.put(BaseUrl + 'donation/approve_delete', data).then((res) => {
      toast.success(res.data.message);
      getPendingDeleteDonations()
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsApproveLoading({ id: donation._id, isLoading: false })
    })
  }

  function openViewModal(donation: IDonation) {
    setSelectedDonation(donation)
    setShowViewModal(true)
  }

  function closeViewEditModal() {
    setShowViewModal(false)
    setSelectedDonation(null)
  }

  return (
    <div>
      <h3>Pending Donations</h3>
      {isLoading ? (
        <LinearProgress />
      ) : (pendingDonations.length === 0 && pendingEditsDonations.length === 0 && pendingDeletesDonations.length === 0) ? (
        <p>No pending donations</p>
      ) : (
        <div className="table-responsive pt-4 px-4" style={{ borderRadius: '8px', background: 'white' }}>
          <table className="table table-hover align-middle">
            <thead>
              <tr>
                <th>Membership No</th>
                <th>Name</th>
                <th>Contribution</th>
                <th>Amount</th>
                <th>Description</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {pendingDonations.map((donation: IDonation, index: number) =>
                <tr key={index}>
                  <td onClick={() => openViewModal(donation)}>{donation.memberId.membershipNo}</td>
                  <td onClick={() => openViewModal(donation)}>
                    {donation.memberId.title + ". " + donation.memberId.firstname + " " + donation.memberId.lastname}
                  </td>
                  <td onClick={() => openViewModal(donation)}>{donation.contributionType}</td>
                  <td onClick={() => openViewModal(donation)}>{donation.amount.toFixed(2)}</td>
                  <td onClick={() => openViewModal(donation)}>{donation.description}</td>
                  <td onClick={() => openViewModal(donation)}>{new Date(donation.dateDonated).toLocaleDateString()}</td>
                  <td>
                    <FilledButton
                      onClick={() => approveDonation(donation)}
                      endIcon={(isApproveLoading.id === donation._id && isApproveLoading.isLoading) && <CustomCircularProgress />}
                    >
                      Approve
                    </FilledButton>
                  </td>
                </tr>
              )}
              {pendingEditsDonations.map((donation: IDonation, index: number) =>
                <tr key={index}>
                  <td onClick={() => openViewModal(donation)}>{donation.memberId.membershipNo}</td>
                  <td onClick={() => openViewModal(donation)}>
                    {donation.memberId.title + ". " + donation.memberId.firstname + " " + donation.memberId.lastname}
                  </td>
                  <td onClick={() => openViewModal(donation)}>{donation.contributionType}</td>
                  <td onClick={() => openViewModal(donation)}>{donation.amount.toFixed(2)}</td>
                  <td onClick={() => openViewModal(donation)}>{donation.description}</td>
                  <td onClick={() => openViewModal(donation)}>{new Date(donation.dateDonated).toLocaleDateString()}</td>
                  <td>
                    <FilledButton
                      onClick={() => approveEditDonation(donation)}
                      endIcon={(isApproveLoading.id === donation._id && isApproveLoading.isLoading) && <CustomCircularProgress />}
                    >
                      Approve
                    </FilledButton>
                  </td>
                </tr>
              )}
              {pendingDeletesDonations.map((donation: IDonation, index: number) =>
                <tr key={index}>
                  <td onClick={() => openViewModal(donation)}>{donation.memberId.membershipNo}</td>
                  <td onClick={() => openViewModal(donation)}>
                    {donation.memberId.title + ". " + donation.memberId.firstname + " " + donation.memberId.lastname}
                  </td>
                  <td onClick={() => openViewModal(donation)}>{donation.contributionType}</td>
                  <td onClick={() => openViewModal(donation)}>{donation.amount.toFixed(2)}</td>
                  <td onClick={() => openViewModal(donation)}>{donation.description}</td>
                  <td onClick={() => openViewModal(donation)}>{new Date(donation.dateDonated).toLocaleDateString()}</td>
                  <td>
                    <FilledButton
                      onClick={() => approveDeleteDonation(donation)}
                      endIcon={(isApproveLoading.id === donation._id && isApproveLoading.isLoading) && <CustomCircularProgress />}
                    >
                      Approve
                    </FilledButton>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      <ViewDonationModal show={showViewModal} onHide={() => closeViewEditModal()} donation={selectedDonation} />
    </div>
  )
}

export default PendingContributions