import React, { useContext, useEffect, useState } from 'react'
import { CustomAutoComplete, CustomCheckBox, CustomCircularProgress, CustomDatePicker, CustomTextField, FilledButton, HollowButton } from '../../Components/CustomMui/CustomMui'
import { Dayjs } from 'dayjs';
import { Box, Button, Chip, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { toast } from 'react-toastify';
import { BaseUrl, isEmptyString, toBase64, uploadToS3 } from '../../Helpers/HelperFunctions';
import axios from 'axios';
import { IMember } from '../../Helpers/Interfaces';
import { BranchTypes, TitleTypes, UserContextType } from '../../Helpers/Types';
import { UserContext } from '../../Store/Store';
import { Modal } from 'react-bootstrap';
import './Members.css'
import { CameraAltOutlined, Cancel } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

type AddMemberModalProps = {
  show: boolean;
  onHide: CallableFunction;
}

function AddMemberModal(props: Readonly<AddMemberModalProps>) {
  const navigate = useNavigate()
  const [isMembersLoading, setIsMembersLoading] = useState(false as boolean);
  const [isAddLoading, setIsAddLoading] = useState(false as boolean);
  const [members, setMembers] = useState([] as IMember[])
  const [profilePic, setProfilePic] = useState(null as File | null)
  const [profilePicPreview, setProfilePicPreview] = useState("" as string)
  const [membershipNo, setMembershipNo] = useState("" as string)
  const [proposer, setProposer] = useState(null as IMember | null);
  const [seconder, setSeconder] = useState(null as IMember | null);
  const [joinedDate, setJoinedDate] = useState(null as Dayjs | null);
  const [title, setTitle] = useState("Mr" as TitleTypes)
  const [firstname, setFirstname] = useState("" as string);
  const [lastname, setLastname] = useState("" as string);
  const [otherName, setOtherName] = useState("" as string);
  const [dob, setDob] = useState(null as Dayjs | null);
  const [nic_passport, setNicPassport] = useState("" as string);
  const [email, setEmail] = useState("" as string);
  const [landline, setLandline] = useState("" as string);
  const [mobile1, setMobile1] = useState("" as string);
  const [mobile2, setMobile2] = useState("" as string);
  const [fax, setFax] = useState("" as string);
  const [address, setAddress] = useState("" as string);
  const [postalCode, setPostalCode] = useState("" as string);
  const [city, setCity] = useState("" as string);
  const [isLocalMailing, setIsLocalMailing] = useState(true as boolean);
  const [isForeignResident, setIsForeignResident] = useState(false as boolean);
  const [foreignAddress, setForeignAddress] = useState("" as string);
  const [foreignPostalCode, setForeignPostalCode] = useState("" as string);
  const [foreignCity, setForeignCity] = useState("" as string);
  const [country, setCountry] = useState("" as string);
  const [isForeignMailing, setIsForeignMailing] = useState(false as boolean);
  const [occupation, setOccupation] = useState("" as string);
  const [company, setCompany] = useState("" as string);
  const [companyAddress, setCompanyAddress] = useState("" as string);
  const [companyPostalCode, setCompanyPostalCode] = useState("" as string);
  const [companyCity, setCompanyCity] = useState("" as string);
  const [companyCountry, setCompanyCountry] = useState("" as string);
  const [indexNo, setIndexNo] = useState("" as string);
  const [admissionYear, setAdmissionYear] = useState(null as Dayjs | null);
  const [leavingYear, setLeavingYear] = useState(null as Dayjs | null);
  const [branch, setBranch] = useState([] as BranchTypes[]);
  const [juniorTransferDate, setJuniorTransferDate] = useState(null as Dayjs | null);
  const [selectedDocuments, setSelectedDocuments] = useState([] as File[]);
  const { user } = useContext(UserContext) as UserContextType;

  useEffect(() => {
    getMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getMembers() {
    setIsMembersLoading(true)
    await axios.get(BaseUrl + 'member').then((response) => {
      setMembers(response.data.result)
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsMembersLoading(false)
    })
  }

  const addMember = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsAddLoading(true)

    if (isForeignResident) {
      if (isEmptyString(foreignAddress) || isEmptyString(foreignPostalCode) || isEmptyString(foreignCity) || isEmptyString(country)) {
        toast.error("Please enter the address")
        setIsAddLoading(false)
        return
      }
    }

    if (!isForeignResident) {
      if (isEmptyString(address) || isEmptyString(postalCode) || isEmptyString(city)) {
        toast.error("Please enter the address")
        setIsAddLoading(false)
        return
      }
    }

    const data = {
      profilePic: profilePic ? await uploadToS3(profilePic, membershipNo, true) : "",
      documents: selectedDocuments.length > 0 ? await Promise.all(selectedDocuments.map(async (file) => await uploadToS3(file, membershipNo, false))) : [],
      membershipNo, proposer: proposer?._id, seconder: seconder?._id, joinedDate, title, firstname, lastname, otherName, dob, nic_passport, email, landline, mobile1, mobile2, fax, address, postalCode, city, isLocalMailing, isForeignResident, foreignAddress, foreignPostalCode, foreignCity, country, isForeignMailing, occupation, company, companyAddress, companyPostalCode, companyCity, companyCountry, indexNo, admissionYear, leavingYear, branch, juniorTransferDate, addedBy: user._id
    }

    await axios.post(BaseUrl + 'member/', data).then((res) => {
      toast.success(res.data.message)
      closeModal()
      navigate('/dashboard')
    }).catch((error) => {
      toast.error(error.response.data.message || error.message)
    }).finally(() => {
      setIsAddLoading(false)
    })
  }

  function closeModal() {
    props.onHide();
    clearFields();
  }

  function clearFields() {
    setProfilePicPreview("")
    setProfilePic(null)
    setMembershipNo("")
    setProposer(null)
    setSeconder(null)
    setJoinedDate(null)
    setTitle("Mr")
    setFirstname("")
    setLastname("")
    setOtherName("")
    setDob(null)
    setNicPassport("")
    setEmail("")
    setLandline("")
    setMobile1("")
    setMobile2("")
    setFax("")
    setAddress("")
    setPostalCode("")
    setCity("")
    setIsLocalMailing(true)
    setIsForeignResident(false)
    setForeignAddress("")
    setForeignPostalCode("")
    setForeignCity("")
    setCountry("")
    setIsForeignMailing(false)
    setOccupation("")
    setCompany("")
    setCompanyAddress("")
    setCompanyPostalCode("")
    setCompanyCity("")
    setCompanyCountry("")
    setIndexNo("")
    setAdmissionYear(null)
    setLeavingYear(null)
    setBranch([])
    setJuniorTransferDate(null)
    setSelectedDocuments([])
  }

  return (
    <Modal show={props.show} size='xl' onHide={() => closeModal()} centered scrollable>
      <Modal.Header closeButton><h3>Add Member</h3></Modal.Header>
      <Modal.Body>
        <form id='addMemberForm' onSubmit={addMember}>
          <div className='row p-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
            <h5>Membership Details</h5>
            <div className='row'>
              <div className='col-xl-3 justify-content-center'>
                <img className="img-fluid profile-pic-upload"
                  src={profilePicPreview !== "" ? profilePicPreview : '/images/avatar.jpg'} alt="preview"
                  onClick={() => document.getElementById("profile_pic")?.click()}
                />
                <input style={{ display: "none" }}
                  type="file" id="profile_pic" accept="image/png, image/jpeg, image/* " capture="user"
                  onChange={(e) => {
                    if (e.target.files) {
                      setProfilePic(e.target.files[0])
                      toBase64(e.target.files[0]).then((url) => {
                        setProfilePicPreview(url as string)
                      });
                    }
                  }}
                />
              </div>
              <div className='col-xl-9'>
                <div className='row'>
                  <div className='col-xl-6'>
                    <CustomTextField label="Membership No" type='text' value={membershipNo}
                      onChange={(e) => setMembershipNo(e.target.value)}
                    />
                  </div>
                  <div className='col-xl-6'>
                    <CustomDatePicker label="Joined Date" value={joinedDate}
                      onChange={(newValue: React.SetStateAction<Dayjs | null>) => setJoinedDate(newValue)}
                    />
                  </div>
                  <div className='col-xl-6'>
                    <CustomAutoComplete
                      options={members}
                      getOptionLabel={(option: IMember) => option.membershipNo + " - " + option.firstname + " " + option.lastname}
                      value={proposer}
                      onChange={(event: React.SyntheticEvent<Element, Event>, newValue: IMember) => setProposer(newValue)}
                      label="Proposer"
                      isLoading={isMembersLoading}
                    />
                  </div>
                  <div className='col-xl-6'>
                    <CustomAutoComplete
                      options={members}
                      getOptionLabel={(option: IMember) => option.membershipNo + " - " + option.firstname + " " + option.lastname}
                      value={seconder}
                      onChange={(event: React.SyntheticEvent<Element, Event>, newValue: IMember) => setSeconder(newValue)}
                      label="Seconder"
                      isLoading={isMembersLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-3 p-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
            <h5>Personal Details</h5>
            <div className='col-xl-2'>
              <CustomTextField select label="Title" value={title} onChange={(e) => setTitle(e.target.value as TitleTypes)} >
                <MenuItem value='Mr'>Mr</MenuItem>
                <MenuItem value='Rev'>Rev</MenuItem>
                <MenuItem value='Ven'>Ven</MenuItem>
                <MenuItem value='Dr'>Dr</MenuItem>
                <MenuItem value='Prof'>Prof</MenuItem>
              </CustomTextField>
            </div>
            <div className='col-xl-5'>
              <CustomTextField label="First Name" type='name' value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </div>
            <div className='col-xl-5'>
              <CustomTextField
                label="Last Name" type='name' value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Other Name" type='name' value={otherName} required={false}
                onChange={(e) => setOtherName(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="NIC/Passport" type='text' value={nic_passport}
                onChange={(e) => setNicPassport(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomDatePicker
                label="Date of Birth" value={dob}
                onChange={(newValue: React.SetStateAction<Dayjs | null>) => setDob(newValue)}
              />
            </div>
          </div>
          <div className='row mt-3 p-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
            <h5>Contact Details</h5>
            <div className='col-xl-4'>
              <CustomTextField label="Email" type='email' value={email} required={true}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField label="Landline" type='tel' value={landline} required={false}
                onChange={(e) => setLandline(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Mobile 1" type='tel' value={mobile1}
                onChange={(e) => setMobile1(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Mobile 2" type='tel' value={mobile2} required={false}
                onChange={(e) => setMobile2(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Fax" type='text' value={fax} required={false}
                onChange={(e) => setFax(e.target.value)}
              />
            </div>
          </div>
          <div className='row align-items-center mt-3 p-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
            <h5>Local Residential Details</h5>
            <div className='col-xl-12'>
              <CustomTextField label="Address" multiline rows={3} value={address} required={false}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Postal code" type='text' value={postalCode} required={false}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="City" type='text' value={city} required={false}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <FormGroup>
                <FormControlLabel
                  control={<CustomCheckBox checked={isLocalMailing}
                    onChange={(e) => { setIsForeignMailing(!e.target.checked); setIsLocalMailing(e.target.checked) }}
                  />}
                  label="Mailing address"
                />
              </FormGroup>
            </div>
          </div>
          <FormGroup>
            <FormControlLabel
              control={<CustomCheckBox checked={isForeignResident}
                onChange={(e) => setIsForeignResident(e.target.checked)}
              />}
              className='mt-3'
              label="I am a foreign resident"
            />
          </FormGroup>
          {isForeignResident && (
            <div className='row align-items-center p-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
              <h5>Foreign Residential Details</h5>
              <div className='col-xl-12'>
                <CustomTextField label="Address" multiline rows={3} value={foreignAddress}
                  onChange={(e) => setForeignAddress(e.target.value)} required={false}
                />
              </div>
              <div className='col-xl-4'>
                <CustomTextField
                  label="Postal code" type='text' value={foreignPostalCode} required={false}
                  onChange={(e) => setForeignPostalCode(e.target.value)}
                />
              </div>
              <div className='col-xl-4'>
                <CustomTextField
                  label="City" type='text' value={foreignCity} required={false}
                  onChange={(e) => setForeignCity(e.target.value)}
                />
              </div>
              <div className='col-xl-4'>
                <CustomTextField
                  label="Country" type='text' value={country} required={false}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
              <div className='col-xl-4'>
                <FormGroup>
                  <FormControlLabel
                    control={<CustomCheckBox checked={isForeignMailing}
                      onChange={(e) => { setIsLocalMailing(!e.target.checked); setIsForeignMailing(e.target.checked) }}
                    />}
                    label="Mailing address"
                  />
                </FormGroup>
              </div>
            </div>
          )}
          <div className='row align-items-center mt-3 p-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
            <h5>Occupation Details</h5>
            <div className='col-xl-4'>
              <CustomTextField
                label="Occupation" type='text' value={occupation}
                onChange={(e) => setOccupation(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Company name" type='text' value={company} required={false}
                onChange={(e) => setCompany(e.target.value)}
              />
            </div><div className='col-xl-12'>
              <CustomTextField label="Company Address" multiline rows={3} value={companyAddress} required={false}
                onChange={(e) => setCompanyAddress(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Company Postal code" type='text' value={companyPostalCode} required={false}
                onChange={(e) => setCompanyPostalCode(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Company City" type='text' value={companyCity} required={false}
                onChange={(e) => setCompanyCity(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomTextField
                label="Company Country" type='text' value={companyCountry} required={false}
                onChange={(e) => setCompanyCountry(e.target.value)}
              />
            </div>
          </div>
          <div className='row align-items-center mt-3 p-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
            <h5>College Details</h5>
            <div className='col-xl-4'>
              <CustomTextField label="Index No" type='text' value={indexNo}
                onChange={(e) => setIndexNo(e.target.value)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomDatePicker
                label="Year of admission" value={admissionYear} views={['year']}
                onChange={(newValue: React.SetStateAction<Dayjs | null>) => setAdmissionYear(newValue)}
              />
            </div>
            <div className='col-xl-4'>
              <CustomDatePicker
                label="Year of leaving" value={leavingYear} views={['year']}
                onChange={(newValue: React.SetStateAction<Dayjs | null>) => setLeavingYear(newValue)}
              />
            </div>
            <div className='col-xl-4'>
              <FormControl fullWidth required margin='normal'>
                <InputLabel>Branch Attended</InputLabel>
                <Select
                  multiple value={branch} fullWidth required
                  onChange={(e) => setBranch(e.target.value as BranchTypes[])}
                  input={<OutlinedInput label="Branch Attended" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  <MenuItem value='Town'>Town</MenuItem>
                  <MenuItem value='Nalluruwa'>Nalluruwa</MenuItem>
                  <MenuItem value='Wekada'>Wekada</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className='row align-items-center mt-3 p-3' style={{ border: '1px solid var(--background)', borderRadius: '8px' }}>
            <h5>Junior OBA Details</h5>
            <div className='col-xl-4'>
              <CustomDatePicker
                label="Transfer date" value={juniorTransferDate}
                onChange={(newValue: React.SetStateAction<Dayjs | null>) => setJuniorTransferDate(newValue)}
              />
            </div>
          </div>
          <div className="file-upload-container p-3 mt-4">
            <div>
              <h5>Upload documents</h5>
              <p>Accepts only JPEG, PNG, PDF formats</p>
              <input
                type="file" id="file-upload" accept=".pdf, image/png, image/jpeg, image/* " multiple
                onChange={(e) => {
                  if (e.target.files) {
                    setSelectedDocuments([...selectedDocuments, e.target.files[0]])
                  }
                }}
              />
              <label htmlFor="file-upload">
                <Button className='secondary-button' variant='outlined' disableElevation startIcon={<CameraAltOutlined />} component="span">
                  Upload document
                </Button>
              </label>
            </div>
            {selectedDocuments.length > 0 && (
              <ul>
                {selectedDocuments.map((file: File) => (
                  <li key={file.name} className='preview mb-3'>
                    <div className='justify-between'>
                      <div className='me-5'>{file.name}</div>
                      <IconButton onClick={() => setSelectedDocuments(selectedDocuments.filter((element: any) => element !== file))}>
                        <Cancel />
                      </IconButton>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className='gap-2'>
        <HollowButton onClick={() => closeModal()}>Close</HollowButton>
        <FilledButton form='addMemberForm' type='submit' endIcon={isAddLoading && <CustomCircularProgress />}>
          Add Member
        </FilledButton>
      </Modal.Footer>
    </Modal>
  )
}

export default AddMemberModal