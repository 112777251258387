import { IconButton, InputAdornment, LinearProgress } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { CustomTextField, FilledButton } from '../../Components/CustomMui/CustomMui'
import { BaseUrl, getSearchResults } from '../../Helpers/HelperFunctions'
import { IMember } from '../../Helpers/Interfaces'
import { Add, ChangeCircle, Close, Lock, Search } from '@mui/icons-material';
import AddAdminModal from './AddAdminModal'
import axios from 'axios'
import { toast } from 'react-toastify'
import { UserContext } from '../../Store/Store'
import { UserContextType } from '../../Helpers/Types'
import RemoveAdminModal from './RemoveAdminModal'
import ChangeUserRoleModal from './ChangeUserRoleModal'
import ChangePWModal from './ChangePWModal'

function Admins() {
  const { user } = useContext(UserContext) as UserContextType;
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [admins, setAdmins] = useState([] as IMember[])
  const [selectedAdmin, setSelectedAdmin] = useState(null as IMember | null)
  const [searchedAdmins, setSearchedAdmins] = useState([] as IMember[])
  const [showAddModal, setShowAddModal] = useState(false as boolean)
  const [showRemoveModal, setShowRemoveModal] = useState(false as boolean)
  const [showChangeUserRoleModal, setShowChangeUserRoleModal] = useState(false as boolean)
  const [showChangePWModal, setShowChangePWModal] = useState(false as boolean)

  useEffect(() => {
    getAdmins()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getAdmins() {
    setIsLoading(true)
    await axios.get(BaseUrl + 'member/admins').then((response) => {
      setAdmins(response.data.result)
      setSearchedAdmins(response.data.result)
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handleSearch = (searchKeyWord: string) => {
    const searchResults = getSearchResults(searchKeyWord, admins)
    setSearchedAdmins(searchResults)
  }

  const removeAdmin = (admin: IMember) => {
    if (admin.userRole === "Super Admin" && admins.filter((admin) => admin.userRole === "Super Admin").length === 1) {
      toast.error("There should be at least one super admin")
      return;
    }

    if (admins.length === 1) {
      toast.error("There should be at least one admin")
      return;
    }

    setSelectedAdmin(admin)
    setShowRemoveModal(true)
  }

  const changeUserRole = (admin: IMember) => {
    if (admin.userRole === "Super Admin" && admins.filter((admin) => admin.userRole === "Super Admin").length === 1) {
      toast.error("There should be at least one super admin")
      return;
    }
    setSelectedAdmin(admin)
    setShowChangeUserRoleModal(true)
  }

  const changePw = (admin: IMember) => {
    setSelectedAdmin(admin)
    setShowChangePWModal(true)
  }

  return (
    <>
      <div className='page-wrapper'>
        <div className='justify-between flex-wrap mb-3'>
          <h2>Admins</h2>
          {user.userRole === "Super Admin" && (
            <FilledButton onClick={() => setShowAddModal(true)} endIcon={<Add />}>Add admin</FilledButton>
          )}
        </div>
        <CustomTextField label="Search" size='small' type='search' fullWidth={false}
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            )
          }}
        />
        {isLoading ? (<LinearProgress />) : (
          <div className="table-responsive">
            <table className="table table-hover mt-4 align-middle">
              <thead>
                <tr>
                  <th>Membership No</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Role</th>
                  {user.userRole === "Super Admin" && (
                    <>
                      <th className='text-center'>Change PW</th>
                      <th className='text-center'>Change Role</th>
                      <th className='text-center'>Remove</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {searchedAdmins.map((admin: IMember, index: number) =>
                  <tr key={index}>
                    <td>{admin.membershipNo}</td>
                    <td>{admin.title + ". " + admin.firstname + " " + admin.lastname}</td>
                    <td>{admin.email}</td>
                    <td>{admin.mobile1}</td>
                    <td>{admin.userRole}</td>
                    {user.userRole === "Super Admin" && (
                      <>
                        <td className='text-center'><IconButton onClick={() => changePw(admin)}><Lock /></IconButton></td>
                        <td className='text-center'><IconButton onClick={() => changeUserRole(admin)}><ChangeCircle /></IconButton></td>
                        <td className='text-center'><IconButton onClick={() => removeAdmin(admin)}><Close /></IconButton></td>
                      </>
                    )}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div >
      <AddAdminModal
        show={showAddModal}
        adminIds={admins.map((admin) => admin._id)}
        reloadAdmins={() => getAdmins()}
        onHide={() => setShowAddModal(false)}
      />
      {selectedAdmin && (
        <>
          <RemoveAdminModal
            show={showRemoveModal}
            admin={selectedAdmin as IMember}
            reloadAdmins={() => getAdmins()}
            onHide={() => { setShowRemoveModal(false); setSelectedAdmin(null) }}
          />
          <ChangeUserRoleModal
            show={showChangeUserRoleModal}
            admin={selectedAdmin as IMember}
            reloadAdmins={() => getAdmins()}
            onHide={() => { setShowChangeUserRoleModal(false); setSelectedAdmin(null) }}
          />
          <ChangePWModal
            show={showChangePWModal}
            admin={selectedAdmin as IMember}
            reloadAdmins={() => getAdmins()}
            onHide={() => { setShowChangePWModal(false); setSelectedAdmin(null) }}
          />
        </>
      )}
    </>
  )
}

export default Admins