import React, { useEffect, useRef, useState } from 'react'
import { IMember } from '../../Helpers/Interfaces';
import { BaseUrl } from '../../Helpers/HelperFunctions';
import axios from 'axios';
import { toast } from 'react-toastify';
import { LinearProgress } from '@mui/material';
import './MemberCard.css'
import { FilledButton } from '../../Components/CustomMui/CustomMui';
import { CloudDownload } from '@mui/icons-material';
import { useReactToPrint } from "react-to-print";

function MemberCards() {
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [members, setMembers] = useState([] as IMember[])
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getMembers() {
    setIsLoading(true)
    await axios.get(BaseUrl + 'member').then((response) => {
      setMembers(response.data.result.filter((member: IMember) => member.status === 'Active'))
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className='page-wrapper'>
      <div className='justify-between flex-wrap mb-3'>
        <h2>Member Cards</h2>
        <FilledButton endIcon={<CloudDownload />} onClick={() => handlePrint()}>Download PDF</FilledButton>
      </div>
      {isLoading ? (<LinearProgress />) : (
        <div ref={componentRef}>
          <div className='member-card-grid'>
            {members.map((member: IMember) => (
              <div className='member-card'>
                <h5>{member.title}. {member.firstname} {member.lastname}</h5>
                {member.isLocalMailing ? (
                  <div>
                    <div>{member.address}</div>
                    <div>{member.postalCode}</div>
                  </div>
                ) : member.isForeignMailing ? (
                  <div>
                    <div>{member.foreignAddress}</div>
                    <div>{member.foreignPostalCode}</div>
                    <p>{member.country}</p>
                  </div>
                ) : null}
                <div>{member.mobile1}</div>
                <div>{member.email}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default MemberCards