import { IconButton, LinearProgress } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { FilledButton, HollowButton } from '../../../Components/CustomMui/CustomMui'
import { BaseUrl } from '../../../Helpers/HelperFunctions'
import { IDonation, IProject } from '../../../Helpers/Interfaces'
import { Add, ArrowBack, Close, Edit } from '@mui/icons-material';
import AddEditDonationModal from './AddEditDonationModal'
import axios from 'axios'
import { toast } from 'react-toastify'
import { UserContext } from '../../../Store/Store'
import { UserContextType } from '../../../Helpers/Types'
import { useNavigate, useParams } from 'react-router-dom'
import ViewDonationModal from './ViewDonationModal'
import DeleteDonationModal from './DeleteDonationModal'

function Donations() {
  const { user } = useContext(UserContext) as UserContextType;
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [project, setProject] = useState(null as IProject | null);
  const [donations, setDonations] = useState([] as IDonation[])
  const [showAddEditModal, setShowAddEditModal] = useState(false as boolean)
  const [showViewModal, setShowViewModal] = useState(false as boolean)
  const [selectedDonation, setSelectedDonation] = useState(null as IDonation | null)
  const [showDeleteModal, setShowDeleteModal] = useState(false as boolean)
  const { projectId } = useParams()

  useEffect(() => {
    getProjectById()
    getDonationsByProject()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getProjectById() {
    setIsLoading(true)
    await axios.get(BaseUrl + 'project/' + projectId).then((response) => {
      setProject(response.data.result)
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  async function getDonationsByProject() {
    setIsLoading(true)
    await axios.get(BaseUrl + 'donation/project/' + projectId).then((response) => {
      setDonations(response.data.result)
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const editDonation = (donation: IDonation) => {
    setSelectedDonation(donation)
    setShowAddEditModal(true)
  }

  const viewDonation = (donation: IDonation) => {
    setSelectedDonation(donation)
    setShowViewModal(true)
  }


  const removeDonation = async (donation: IDonation) => {
    setSelectedDonation(donation)
    setShowDeleteModal(true)
  }

  return (
    <>
      <div className='page-wrapper'>
        <div className='justify-between flex-wrap'>
          <div className='justify-between mb-3 gap-2'>
            <HollowButton startIcon={<ArrowBack />} onClick={() => navigate(-1)}>Back</HollowButton>
            {project && <h2>{project.name} - Donations</h2>}
          </div>
          {user.userRole === "Super Admin" && (
            <FilledButton onClick={() => setShowAddEditModal(true)} endIcon={<Add />}>Add Donation</FilledButton>
          )}
        </div>
        {isLoading ? (<LinearProgress />) : (
          <div className="table-responsive">
            <table className="table table-hover mt-4 align-middle">
              <thead>
                <tr>
                  <th>Membership No</th>
                  <th>Name</th>
                  <th>Contribution</th>
                  <th>Amount</th>
                  <th>Description</th>
                  <th>Date</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {donations.map((donation: IDonation, index: number) =>
                  <tr key={index}>
                    <td onClick={() => viewDonation(donation)}>{donation.memberId.membershipNo}</td>
                    <td onClick={() => viewDonation(donation)}>
                      {donation.memberId.title + ". " + donation.memberId.firstname + " " + donation.memberId.lastname}
                    </td>
                    <td onClick={() => viewDonation(donation)}>{donation.contributionType}</td>
                    <td onClick={() => viewDonation(donation)}>Rs {donation.amount.toFixed(2)}</td>
                    <td onClick={() => viewDonation(donation)}>{donation.description}</td>
                    <td onClick={() => viewDonation(donation)}>{new Date(donation.createdAt).toLocaleDateString()}</td>
                    <td><IconButton onClick={() => editDonation(donation)}><Edit /></IconButton></td>
                    <td><IconButton onClick={() => removeDonation(donation)}><Close /></IconButton></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div >
      <AddEditDonationModal
        show={showAddEditModal} projectId={projectId as string}
        donation={selectedDonation}
        reloadDonations={() => getDonationsByProject()}
        onHide={() => { setSelectedDonation(null); setShowAddEditModal(false) }}
      />
      <ViewDonationModal
        show={showViewModal} donation={selectedDonation}
        onHide={() => { setSelectedDonation(null); setShowViewModal(false) }}
      />
      <DeleteDonationModal
        show={showDeleteModal} onHide={() => { setShowDeleteModal(false); setSelectedDonation(null) }}
        donation={selectedDonation as IDonation} reloadDonations={() => getDonationsByProject()}
      />
    </>
  )
}

export default Donations