import { People } from '@mui/icons-material'
import { CustomCircularProgress } from '../../Components/CustomMui/CustomMui';

type DashboardCardProps = {
  title: string;
  value: number;
  isLoading: boolean;
}

function DashboardCard(props: Readonly<DashboardCardProps>) {

  return (
    <div className='dashboard-card'>
      <div className='justify-between mb-2'>
        <div>
          <span>{props.title}</span>
          {props?.isLoading ? <CustomCircularProgress /> : <h3>{props.value}</h3>}
        </div>
        <div className='icon-box'>
          <People />
        </div>
      </div>
    </div>
  )
}

export default DashboardCard