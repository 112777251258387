import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from './Store/Store';
import { UserContextType } from './Helpers/Types';
import { isEmptyString } from './Helpers/HelperFunctions';
import './App.css';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Pages/Login/Login';
import Layout from './Components/Layout/Layout';
import Dashboard from './Pages/Dashboard/Dashboard';
import Members from './Pages/Members/Members';
import Admins from './Pages/Admin/Admins';
import MemberCards from './Pages/MemberCards/MemberCards';
import CommitteeMembers from './Pages/Committee/CommitteeMembers';
import Donations from './Pages/Projects/Donations/Donations';
import Projects from './Pages/Projects/Projects';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const { user } = useContext(UserContext) as UserContextType;

  useEffect(() => {
    validateLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  function validateLogin() {
    if (!isEmptyString(user.firstname)) {
      return setLoggedIn(true);
    }

    return setLoggedIn(false);
  }

  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={true} closeButton={false} />
        <Routes>
          <Route index path="/" element={loggedIn ? <Navigate to={"/dashboard"} /> : <Login />} />
          <Route element={<Layout><Outlet /></Layout>}>
            <Route index path="/dashboard" element={loggedIn ? < Dashboard /> : <Navigate to={"/"} />} />
            <Route index path="/members" element={loggedIn ? < Members /> : <Navigate to={"/"} />} />
            <Route index path="/committee" element={loggedIn ? < CommitteeMembers /> : <Navigate to={"/"} />} />
            <Route index path="/admins" element={loggedIn ? < Admins /> : <Navigate to={"/"} />} />
            <Route index path="/projects" element={loggedIn ? < Projects /> : <Navigate to={"/"} />} />
            <Route index path="/projects/:projectId" element={loggedIn ? < Donations /> : <Navigate to={"/"} />} />
            <Route index path="/member_cards" element={loggedIn ? < MemberCards /> : <Navigate to={"/"} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
