import React from 'react'
import { IChange, IMember } from '../Helpers/Interfaces'
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Card, CardContent, Divider, Grid } from '@mui/material'
import { Circle, ExpandMore, History } from '@mui/icons-material'

interface IEditHistoryProps {
  changes: IChange[]
  addedBy: IMember
  createdAt: Date
  approvedBy: IMember
  approvedAt: Date
}

function EditHistory(props: IEditHistoryProps) {
  const dateFields = ['dateDonated']

  const sortChanges = (changes: IChange[]) => {
    return changes.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
  }

  return (
    <div>
      <Divider className='mb-4' />
      <Accordion elevation={0} sx={{ border: '1px solid var(--background)' }}>
        <AccordionSummary expandIcon={<ExpandMore />}><History className='me-2' /> History</AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {(props.changes && props.changes.length > 0) && (
              <>
                {sortChanges(props.changes).map((change, index) => (
                  <Grid item xs={12} key={index}>
                    <Card elevation={0} sx={{ border: '1px solid var(--background)' }}>
                      <CardContent>
                        <div className='justify-between mb-3 gap-2 flex-wrap'>
                          <div className='d-flex align-items-center gap-2'>
                            <Avatar src={change.updatedBy.profilePic} />
                            <div>
                              <p className='mb-0'>{change.updatedBy.firstname} {change.updatedBy.lastname} - {change.updatedBy.membershipNo}</p>
                              <small className='text-muted'>on {new Date(change.updatedAt).toLocaleDateString()}</small>
                            </div>
                          </div>
                          <div className='text-end'>
                            <div className='d-flex align-items-center gap-2'>
                              <Circle fontSize='small' style={{ color: change.status === 'Approved' ? "var(--green)" : "var(--red-200)" }} />
                              <small>{change.status}</small>
                            </div>
                          </div>
                        </div>
                        {change.action === 'Edit' ? (
                          <>
                            {change.fieldsChanged.map((fieldChanged, index) => (
                              <ul className='mb-0' key={index}>
                                {!dateFields.includes(fieldChanged.field) ? (
                                  <li>
                                    <small><b>{fieldChanged.field}</b> changed from <b>{fieldChanged.oldValue}</b> to <b>{fieldChanged.newValue}</b></small>
                                  </li>
                                ) : (
                                  <li>
                                    <small><b>{fieldChanged.field}</b> changed from <b>{new Date(fieldChanged.oldValue).toLocaleDateString()}</b> to <b>{new Date(fieldChanged.newValue).toLocaleDateString()}</b></small>
                                  </li>
                                )}
                              </ul>
                            ))}
                          </>
                        ) : (
                          <p className='mb-0'>Requested for <b>deletion</b></p>
                        )}
                        {change.approvedBy && (
                          <div className='justify-between gap-2 mt-3'>
                            <div>
                              <small className='text-muted'>Approved By</small>
                              <p className='mb-0'>{change.approvedBy.firstname} {change.approvedBy.lastname} - {change.approvedBy.membershipNo}</p>

                            </div>
                            <div>
                              <small className='text-muted'>Approved on {new Date(change.approvedAt).toLocaleDateString()}</small>
                              <p className='mb-0'>{new Date(change.approvedAt).toLocaleDateString()}</p>
                            </div>
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </>
            )}
            <Grid item xs={12}>
              <Card elevation={0} sx={{ border: '1px solid var(--background)' }}>
                <CardContent>
                  <div className='justify-between gap-2 flex-wrap'>
                    <div className='d-flex align-items-center gap-2'>
                      <Avatar src={props?.addedBy?.profilePic} />
                      <div>
                        <p className='mb-0'>{props?.addedBy?.firstname} {props?.addedBy?.lastname} - {props?.addedBy?.membershipNo}</p>
                        <small className='text-muted'>on {new Date(props?.createdAt).toLocaleDateString()}</small>
                      </div>
                    </div>
                    <div className='text-end'>
                      <p>Added By</p>
                    </div>
                  </div>
                  {props?.approvedBy && (
                    <div className='justify-between mt-3 gap-2 flex-wrap'>
                      <div className='d-flex align-items-center gap-2'>
                        <Avatar src={props.approvedBy.profilePic} />
                        <div>
                          <p className='mb-0'>{props.approvedBy.firstname} {props.approvedBy.lastname} - {props.approvedBy.membershipNo}</p>
                          <small className='text-muted'>on {new Date(props.approvedAt).toLocaleDateString()}</small>
                        </div>
                      </div>
                      <div className='text-end'>
                        <p>Approved By</p>
                      </div>
                    </div>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div >
  )
}

export default EditHistory