import { Menu, MenuItem, Sidebar, useProSidebar } from 'react-pro-sidebar'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { HollowButton } from '../CustomMui/CustomMui'
import { LogoutOutlined, KeyboardDoubleArrowLeft, Dashboard, People, AdminPanelSettings, PictureAsPdf, Groups, VolunteerActivism } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { removeTokens } from '../../Helpers/HelperFunctions'
import { useContext } from 'react'
import { UserContextType } from '../../Helpers/Types'
import { UserContext } from '../../Store/Store'

import './SideNavbar.css'

function SideNavbar() {
  const { updateUser } = useContext(UserContext) as UserContextType;
  const location = useLocation()
  const navigate = useNavigate()

  const { toggleSidebar } = useProSidebar()

  const handleLogout = () => {
    removeTokens();
    updateUser({ _id: "", membershipNo: "", profilePic: "", email: "", firstname: "", lastname: "", userRole: "" });
    navigate('/');
  }

  return (
    <Sidebar backgroundColor='var(--foreground)' breakPoint='xl' className='text-start' width='270px' collapsedWidth='0px'>
      <div className='sidebar'>
        <div>
          <div className='justify-between'>
            <img src='/images/Logo.png' alt='logo' className='img-fluid mb-5' />
            <div className='only-mobile text-end'>
              <IconButton onClick={() => toggleSidebar()}>
                <KeyboardDoubleArrowLeft style={{ color: 'var(--primary)' }} />
              </IconButton>
            </div>
          </div>
          <Menu>
            <MenuItem
              active={location.pathname === '/dashboard'} component={<Link to="/dashboard" />}
              icon={<Dashboard style={{ color: location.pathname === '/dashboard' ? undefined : 'var(--primary)' }} />}
            >
              Dashboard
            </MenuItem>
            <MenuItem
              active={location.pathname === '/members'} component={<Link to="/members" />}
              icon={<People style={{ color: location.pathname === '/members' ? undefined : 'var(--primary)' }} />}
            >
              Members
            </MenuItem>
            <MenuItem
              active={location.pathname.includes('projects')} component={<Link to="/projects" />}
              icon={<VolunteerActivism style={{ color: location.pathname === '/projects' ? undefined : 'var(--primary)' }} />}
            >
              Projects
            </MenuItem>
            <MenuItem
              active={location.pathname === '/committee'} component={<Link to="/committee" />}
              icon={<Groups style={{ color: location.pathname === '/committee' ? undefined : 'var(--primary)' }} />}
            >
              Committee
            </MenuItem>
            <MenuItem
              active={location.pathname === '/admins'} component={<Link to="/admins" />}
              icon={<AdminPanelSettings style={{ color: location.pathname === '/admins' ? undefined : 'var(--primary)' }} />}
            >
              Admins
            </MenuItem>
            <MenuItem
              active={location.pathname === '/member_cards'} component={<Link to="/member_cards" />}
              icon={<PictureAsPdf style={{ color: location.pathname === '/member_cards' ? undefined : 'var(--primary)' }} />}
            >
              Member Cards
            </MenuItem>
          </Menu>
        </div>
        <div className='text-center mb-5'>
          <HollowButton startIcon={<LogoutOutlined />} onClick={handleLogout}>Logout</HollowButton>
        </div>
      </div>
    </Sidebar >
  )
}

export default SideNavbar