import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CustomCircularProgress, DeleteButton, HollowButton } from '../../Components/CustomMui/CustomMui'
import { IMember } from '../../Helpers/Interfaces';
import '../../Modal.css'
import axios from 'axios';
import { BaseUrl } from '../../Helpers/HelperFunctions';
import { toast } from 'react-toastify';

type RemoveAdminModalProps = {
  show: boolean;
  onHide: CallableFunction;
  admin: IMember;
  reloadAdmins: CallableFunction;
}

function RemoveAdminModal(props: Readonly<RemoveAdminModalProps>) {
  const [isLoading, setIsLoading] = useState(false as boolean);

  const handleRemoveAdmin = async () => {
    setIsLoading(true)

    await axios.post(BaseUrl + 'member/remove-admin', { memberId: props.admin._id }).then((res) => {
      toast.success(res.data.message)
      props.reloadAdmins();
      props.onHide();
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <Modal show={props.show} onHide={() => props.onHide()} centered>
      <Modal.Header closeButton><h3>Remove admin</h3></Modal.Header>
      <Modal.Body>
        <p><b>{props?.admin.firstname + " " + props?.admin.lastname}</b> bearing the membership number <b>{props?.admin.membershipNo}</b> will not have <b>{props?.admin.userRole}</b> privileges furthermore and wont be able to access this platform</p>
      </Modal.Body>
      <Modal.Footer className='gap-2'>
        <HollowButton onClick={() => props.onHide()}>Cancel</HollowButton>
        <DeleteButton
          onClick={() => handleRemoveAdmin()} endIcon={isLoading && <CustomCircularProgress />}
        >
          Remove
        </DeleteButton>
      </Modal.Footer>
    </Modal>
  )
}

export default RemoveAdminModal