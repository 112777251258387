import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { IChange, IDonation, IMember } from '../../../Helpers/Interfaces';
import '../../../Modal.css'
import { Avatar } from '@mui/material';
import ViewEditModal from '../../Members/ViewEditModal';
import EditHistory from '../../../Components/EditHistory';

type ViewDonationModalProps = {
  show: boolean;
  onHide: CallableFunction;
  donation: IDonation | null;
}

function ViewDonationModal(props: Readonly<ViewDonationModalProps>) {
  const [showViewMemberModal, setShowViewMemberModal] = useState(false as boolean)

  return (
    <>
      <Modal show={props.show} onHide={() => props.onHide()} centered scrollable>
        <Modal.Header closeButton>
          <h3>View Donation</h3>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex align-items-center gap-2 mb-4 cursor-pointer' onClick={() => setShowViewMemberModal(true)}>
            <Avatar src={props.donation?.memberId.profilePic} />
            <div>
              <p className='mb-0'>{props.donation?.memberId.firstname} {props.donation?.memberId.lastname}</p>
              <small className='text-muted'>{props.donation?.memberId.membershipNo}</small>
            </div>
          </div>
          <div className='justify-between gap-2 mb-4'>
            <div>
              <small className='text-muted'>Contribution Type</small>
              <h6>{props.donation?.contributionType}</h6>
            </div>
            <div>
              <small className='text-muted'>Date Contributed</small>
              <h6>{new Date(props.donation?.dateDonated as Date).toLocaleDateString()}</h6>
            </div>
          </div>
          {props.donation?.contributionType === "Financial" && (
            <p>Donated Rs. {props.donation?.amount.toLocaleString()}</p>
          )}
          <p>{props.donation?.description}</p>
          <EditHistory
            changes={props.donation?.changes as IChange[]}
            addedBy={props.donation?.addedBy as IMember}
            approvedBy={props.donation?.approvedBy as IMember}
            approvedAt={props.donation?.approvedDate as Date}
            createdAt={props.donation?.createdAt as Date}
          />
        </Modal.Body>
      </Modal>
      <ViewEditModal
        show={showViewMemberModal} onHide={() => setShowViewMemberModal(false)}
        selectedMember={props.donation?.memberId as IMember}
        reloadMembers={() => { }}
      />
    </>
  )
}

export default ViewDonationModal