import React, { useContext, useState } from 'react'
import { IconButton, InputAdornment } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { CustomCircularProgress, CustomTextField, FilledButton } from '../../Components/CustomMui/CustomMui';
import './Login.css'
import { UserContext } from '../../Store/Store';
import { UserContextType } from '../../Helpers/Types';
import { BaseUrl, saveToken, saveUser } from '../../Helpers/HelperFunctions';
import axios from 'axios';
import { toast } from 'react-toastify';

function Login() {
  const [email, setEmail] = useState("" as string);
  const [password, setPassword] = useState("" as string);
  const [isLoading, setIsLoading] = useState(false as boolean)
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate();
  const { updateUser } = useContext(UserContext) as UserContextType;

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    await axios.post(BaseUrl + 'member/login', { email, password }).then((response) => {
      updateUser(response.data.result);
      saveUser(response.data.result);
      saveToken(response.data.token);
      navigate('/dashboard')
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <div className='login'>
      <form className='login-box' onSubmit={handleLogin}>
        <img src='/images/Logo.png' width={200} className='img-fluid mb-4' alt='logo' />
        <CustomTextField label='Email' type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
        <CustomTextField
          label='Password' type={showPassword ? "text" : "password"} inputProps={{ pattern: '^(.{8,})$' }}
          value={password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword((showPassword) => !showPassword)} >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className='d-flex justify-content-end mt-2 mb-4'>
          {/* <Link to="/forgot">Forgot Password?</Link> */}
        </div>
        <FilledButton fullWidth type='submit'> {isLoading ? <CustomCircularProgress /> : 'Login'} </FilledButton>
      </form>
    </div>
  )
}

export default Login