import { LinearProgress } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { CustomDatePicker, FilledButton } from '../../Components/CustomMui/CustomMui'
import { BaseUrl } from '../../Helpers/HelperFunctions'
import { ICommittee } from '../../Helpers/Interfaces'
import { Add } from '@mui/icons-material';
import AddCommitteeModal from './AddCommitteeModal'
import axios from 'axios'
import { toast } from 'react-toastify'
import { UserContext } from '../../Store/Store'
import { UserContextType } from '../../Helpers/Types'
import dayjs, { Dayjs } from 'dayjs'

function CommitteeMembers() {
  const { user } = useContext(UserContext) as UserContextType;
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [committeeMembers, setCommitteeMembers] = useState([] as ICommittee[])
  const [showAddModal, setShowAddModal] = useState(false as boolean)

  useEffect(() => {
    getCommitteeByYear(new Date().getFullYear())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getCommitteeByYear(year: number) {
    setIsLoading(true)
    await axios.get(BaseUrl + 'committee/year/' + year).then((response) => {
      setCommitteeMembers(response.data.result)
    }).catch((error) => {
      toast.error(error.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <>
      <div className='page-wrapper'>
        <div className='justify-between flex-wrap mb-3'>
          <h2>Committee Members</h2>
          <div className='row align-items-center'>
            <div className='col-5'>
              <CustomDatePicker label="Year" views={['year']} defaultValue={dayjs()} size='small'
                onChange={(newValue: React.SetStateAction<Dayjs | null>) => getCommitteeByYear(dayjs(newValue as Dayjs).year())}
              />
            </div>
            {user.userRole === "Super Admin" && (
              <div className='col-7'>
                <FilledButton onClick={() => setShowAddModal(true)} endIcon={<Add />}>Add Committee Member</FilledButton>
              </div>
            )}
          </div>
        </div>
        {isLoading ? (<LinearProgress />) : (
          <div className="table-responsive">
            <table className="table table-hover mt-4 align-middle">
              <thead>
                <tr>
                  <th>Membership No</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Designation</th>
                </tr>
              </thead>
              <tbody>
                {committeeMembers.map((member: ICommittee, index: number) =>
                  <tr key={index}>
                    <td>{member.memberId.membershipNo}</td>
                    <td>{member.memberId.title + ". " + member.memberId.firstname + " " + member.memberId.lastname}</td>
                    <td>{member.memberId.email}</td>
                    <td>{member.memberId.mobile1}</td>
                    <td>{member.designation}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div >
      <AddCommitteeModal show={showAddModal} reloadCommitteeMembers={() => getCommitteeByYear(new Date().getFullYear())} onHide={() => setShowAddModal(false)} />
    </>
  )
}

export default CommitteeMembers