import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CustomCircularProgress, CustomTextField, FilledButton, HollowButton } from '../../Components/CustomMui/CustomMui'
import { IMember } from '../../Helpers/Interfaces';
import '../../Modal.css'
import { IconButton, InputAdornment } from '@mui/material';
import axios from 'axios';
import { BaseUrl } from '../../Helpers/HelperFunctions';
import { toast } from 'react-toastify';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type ChangePWModalProps = {
  show: boolean;
  onHide: CallableFunction;
  admin: IMember;
  reloadAdmins: CallableFunction;
}

function ChangePWModal(props: Readonly<ChangePWModalProps>) {
  const [isLoading, setIsLoading] = useState(false as boolean);
  const [password, setPassword] = useState("" as string);
  const [showPassword, setShowPassword] = useState(false)

  const handleUpdatePW = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    const data = {
      id: props.admin._id,
      password,
    }

    await axios.put(BaseUrl + 'member/change-pw', data).then((res) => {
      toast.success(res.data.message)
      props.reloadAdmins();
      closeModal()
    }).catch((error) => {
      toast.error(error.response.data.message)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  function closeModal() {
    setPassword("");
    props.onHide();
  }

  return (
    <Modal show={props.show} onHide={() => closeModal()} centered>
      <Modal.Header closeButton>
        <h3>Change Password</h3>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleUpdatePW} id="changePWForm">
          <p>Please enter the new password for <b>{props.admin.userRole}</b>, <b>{props.admin.title + ". " + props.admin.firstname + " " + props.admin.lastname}</b>.</p>
          <small>The new password will be shared with him through email automatically</small>
          <CustomTextField
            label='Password' type={showPassword ? "text" : "password"} inputProps={{ pattern: '^(.{8,})$' }}
            value={password} className='mt-4'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword((showPassword) => !showPassword)} >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            onChange={(e) => setPassword(e.target.value)}
          />
        </form>
      </Modal.Body>
      <Modal.Footer className='gap-2'>
        <HollowButton onClick={() => closeModal()}>Cancel</HollowButton>
        <FilledButton form="changePWForm" type='submit' endIcon={isLoading && <CustomCircularProgress />}>Update</FilledButton>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangePWModal